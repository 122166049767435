<template>
  <div>
    <v-card>
      <s-crud
        :config="config"
        title="Horarios"
        :filter="filter"
        search-input
        add
        edit
        remove
        @save="saveSchedule($event)"
        ref="scrudSchedules"
        @rowSelected="ScheduleSelected($event)"
      >
        <template v-slot="props">
          <v-container>
            <v-row>
              <v-col sm="3" lg="3">
                <s-text
                  v-model="props.item.SdsSchedule"
                  label="Horario"
                  uppercase
                ></s-text>
              </v-col>
              <v-col sm="3">
                <s-select-definition
                  v-model="props.item.SdsTypeSchedule"
                  :def="1526"
                  label="Tipo de Horario"
                ></s-select-definition>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:actions="{ row }">
          <div v-if="row.SdsID > 0" key="row.SdsID">
            <v-btn
              color="error"
              x-small
              @click="dialogScheduleDetailOpen(row)"
              block
            >
              <v-icon small>mdi-alarm-plus</v-icon>
            </v-btn>
          </div>
        </template>
      </s-crud>
      <v-dialog v-model="dialogScheduleDetail" width="800" persistent>
        <v-card>
          <s-toolbar
            color="primary"
            save
            @save="saveDetails()"
            dark
            close
            @close="dialogScheduleDetail = false"
            :label="'Detalle de horario : ' + dialogName"
          >
          </s-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <s-select-definition
                    v-model="props.detail.SddType"
                    :def="1525"
                    label="Tipo"
                    ref="TypeName"
                  ></s-select-definition>
                </v-col>
                <v-col cols="2">
                  <s-text
                    type="number"
                    v-model="props.detail.SddMin"
                    label="Min Ref."
                  ></s-text>
                </v-col>
                <v-col cols="3">
                  <s-text
                    type="time"
                    v-model="props.detail.SddHourBegin"
                    label="Hora Inicio"
                  ></s-text>
                </v-col>
                <v-col cols="3">
                  <s-text
                    type="time"
                    v-model="props.detail.SddHourEnd"
                    label="Hora Final"
                  ></s-text>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex flex-wrap">
                    <v-col cols="2">
                      <v-checkbox
                        v-model="selectAll"
                        label="Seleccionar Todos"
                        :value="true"
                        @change="toggleSelectAll"
                      ></v-checkbox>
                    </v-col>
                    <v-checkbox
                      v-for="item in days"
                      :value="item.DedValue"
                      v-model="props.detail.days"
                      :label="item.DedDescription"
                      :key="item.DedValue"
                      class="mr-2"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <!-- <v-data-table
                dense
                fixed-header=""
                loading="isLoading"
                loading-text="Loading... Please wait"
                group-by="SddTypeName"
                :items="itemsScheduled"
                :headers="headersScheduled"
                :item-key="config.model.SddID"
                class="elevation-1"
              >
                <template v-slot:item.AsgActionScheduled="{ item }">
                  <v-icon small @click="editDetailSchedule(item)">
                    mdi-pencil
                  </v-icon> 
                  <v-icon small @click="deleteDetailSchedule(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table> -->
              <template>
                <div>
                  <!-- Encabezado personalizado -->
                  <table style="width: 100%; border-collapse: collapse;">
                    <thead >
                      <tr>
                        <th rowspan="2" class="text-center" style="width: 70px;  ">Día</th>
                        <th colspan="2" class="text-center" style="width: 100px; ">Horario de Trabajo</th>
                        <th colspan="2" class="text-center" style="width: 100px; ">Intervalo de Horario</th>
                        <th colspan="2" class="text-center" style="width: 100px; ">Intervalo de Refrigerio</th>
                        <th rowspan="2" class="text-center" style="width: 100px; ">Refrigerio</th>
                        <th rowspan="2" class="text-center" style="width: 30px;  ">Acción</th>
                      </tr>
                      <tr>
                        <th class="text-center" > Inicio   </th>
                        <th class="text-center" > Fin      </th>
                        <th class="text-center" > Entrada  </th>
                        <th class="text-center" > Salida   </th>
                        <th class="text-center" > Desde    </th>
                        <th class="text-center" > Hasta    </th>
                      </tr>
                    </thead>
                    <tbody>
                  <!-- Tabla de datos -->
                  <!-- <v-data-table
                    dense
                    fixed-header
                    loading="isLoading"
                    loading-text="Loading... Please wait"
                    :items="itemsScheduled"
                    :headers="headersScheduled"
                    :item-key="config.model.SddID"
                    class="elevation-1"
                    hide-default-header 
                    
                  > -->
                    <!-- Filas de datos -->
                    <!-- <template v-slot:item="{ item }"> -->
                      <tr v-for="(item, SddDateName) in itemsScheduled" :key="SddDateName" >    
                        <td class="text-center" >{{ item.SddDateName }}</td>
                        <td class="text-center" style=" background-color: #f5f5f5;">{{ item.WorkingHoursBegin || '-' }}</td>
                        <td class="text-center" >{{ item.WorkingHoursExit || '-' }}</td>
                        <td class="text-center" style=" background-color: #f5f5f5;">{{ item.TimeSlotEntry || '-' }}</td>
                        <td class="text-center" >{{ item.TimeSlotExit || '-' }}</td>
                        <td class="text-center" style=" background-color: #f5f5f5;">{{ item.BreakEntry || '-' }}</td>
                        <td class="text-center" >{{ item.BreakExit || '-' }}</td>
                        <td class="text-center" style=" background-color: #f5f5f5;">{{ item.Breakfast || '-' }}</td> 
                        <td class="text-center" >
                          <v-icon small @click="editDetailSchedule(item)">mdi-pencil</v-icon>
                          <v-icon small @click="deleteDetailSchedule(item)">mdi-delete</v-icon>
                        </td> 
                      </tr>
                    </tbody>
                    </table>

                    <!-- </template>
                  </v-data-table> -->
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-card>
    <s-load v-if="isLoading"></s-load>
  </div>
</template>

<script>
import SText from "../../../components/Utils/SText.vue";
import SLoad from "../../../components/Layout/Loader.vue";
import _SchedulesService from "../../../services/HumanResource/Schedules";
import _sDefinition from "@/services/General/DefinitionService.js";

export default {
  components: {
    SText,
    SLoad,
  },
  data() {
    return {
      paramsDef: {
        defID: 1524,
        dgrID: 0,
        requestID: this.$fun.getUserID(),
      },
      dialogName: "",
      selectAll: false,
      days: [],
      detail: {
        SddType: 1,
        SddDate: 1,
        SddMin: 45,
        SddHourBegin: null,
        SddHourEnd: null,
        SddTypeName: null,
        SddDateName: null,
      },
      props: {
        detail: {
          SddType: 1,
          SddMin: 45,
          SddHourBegin: null,
          SddHourEnd: null,
          SddTypeName: null,
          days: [],
        },
      },
      dialogScheduleDetail: false,
      isLoading: false,
      filter: {},
      config: {
        service: _SchedulesService,
        model: {
          SdsID: "ID",
          actions: "",
        },
        headers: [
          { text: "ID", value: "SdsID", width: 100 },
          { text: "Horario", value: "SdsSchedule" },
          { text: "Tipo de Horario", value: "SdsTypeScheduleName" },
          { text: "acciones", value: "actions" },
        ],
      },
      filtereditemsScheduled: [],
      headersScheduled: [
        // { text: "Dia", value: "SddDateName", width: "20%" },
        // { text: "Hora Inicio", value: "SddHourBegin", align: "center" },
        // { text: "Hora Final", value: "SddHourEnd", align: "center" },
        // { text: "Refrigerio (Min)", value: "SddMin", align: "center" },
        // {
        //   text: "Acción",
        //   value: "AsgActionScheduled",
        //   width: 40,
        //   align: "center",
        // },
        // { text: 'Día', value: 'SddDateName', align: 'right', width: '5%' },
        // { text: 'Hora Inicio', value: 'WorkingHoursBegin', align: 'left', width: '50px' },
        // { text: 'Hora Final', value: 'WorkingHoursExit', align: 'center', width: '50px' },
        // { text: 'Hora Inicio', value: 'TimeSlotEntry', align: 'center', width: '10%' },
        // { text: 'Hora Final', value: 'TimeSlotExit', align: 'center', width: '10%'},
        // { text: 'Hora Inicio', value: 'BreakEntry', align: 'center', width: '10%' },
        // { text: 'Hora Final', value: 'BreakExit', align: 'center', width: '10%' },
        // { text: 'Refrigerio (Min)', value: 'Breakfast', align: 'center', width: '10%' },
        // { text: 'Acción', value: 'actions', align: 'center', width: '10%', sortable: false },
      ],
      headersShe: [
        { text: 'Nombre', value: 'name' },
        { text: 'Detalles', value: 'details' }, // Columna personalizada
      ],
      // Datos de la tabla
      items: [
        {
          name: 'Item 1',
          subcol1: 'Dato 1',
          subcol2: 'Dato 2',
        },
        {
          name: 'Item 2',
          subcol1: 'Dato 3',
          subcol2: 'Dato 4',
        },
      ],
      AsgActionScheduled: "",
      itemsScheduled: [], //Listado Programados
    };
  },
  methods: {
    editDetailSchedule(item) {
      this.props.detail = item;
      this.props.detail.days = [item.SddDate];
      this.selectAll = false;
    },
    deleteDetailSchedule(item) {
      this.$fun.alert("Desea eliminar registro?", "question").then((r) => {
        if (r.value) {
          this.isLoading = true;
          _SchedulesService
            .deleteDetailByID(item.SddID, this.$fun.getUserID())
            .then((r) => {
              if (r.status === 200) {
                this.ScheduleSelected([{ SdsID: item.SdsID }]);
                this.$fun.alert("Registro eliminado.", "success");
              }
            })
            .catch((error) => {
              this.$fun.alert(error, "error");
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.props.detail.days = this.days.map((item) => item.DedValue);
      } else {
        this.props.detail.days = [];
      }
    },
    selectDay(day) {
      const index = this.props.detail.days.indexOf(day.DedValue);
      if (index === -1) {
        this.props.detail.days.push(day.DedValue);
      } else {
        this.props.detail.days.splice(index, 1);
      }
    },
    saveDetails() {
      this.isLoading = true;
      if (!this.validateInputs()) return;
      _SchedulesService
        .saveDetailsByID(this.props.detail, this.$fun.getUserID())
        .then((r) => {
          if (r.status === 200) {
            this.ScheduleSelected([{ SdsID: this.props.detail.SdsID }]);
            this.clearInputs();
            this.$fun.alert("Registro guardado.", "success");
          }
        })
        .catch((error) => {
          this.$fun.alert(error, "error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearInputs() {
      this.props.detail.SddType = 1;
      this.props.detail.SddMin = 45;
      this.props.detail.SddHourBegin = null;
      this.props.detail.SddHourEnd = null;
      this.props.detail.days = [];
      this.selectAll = false;
    },
    validateInputs() {
      if (!this.props.detail.SddHourBegin || !this.props.detail.SddHourEnd) {
        this.$fun.alert(
          "Debe seleccionar una hora de inicio y fin.",
          "warning"
        );
        return;
      }
      if (this.props.detail.SddMin < 0) {
        this.$fun.alert(
          "El tiempo de refrigerio no puede ser menor a 0.",
          "warning"
        );
        return;
      }
      return true;
    },
    dialogScheduleDetailOpen(event) {
      this.dialogName = event.SdsSchedule || "Nuevo";
      this.clearInputs();
      this.dialogScheduleDetail = true;
    },
    saveSchedule(item) {
      item.UsrCreateID = this.$fun.getUserID();
      item.save();
    },
    ScheduleSelected(item) {
      if (item.length > 0) {
        this.props.detail.SdsID = item[0].SdsID;
        this.filtereditemsScheduled = item[0].SchedulesDetails;
        _SchedulesService
          .getDetailsByID(item[0].SdsID, this.$fun.getUserID())
          .then((r) => {
            if (r.status === 200) {
              this.itemsScheduled = r.data;
            }
          })
          .catch((error) => {
            this.$fun.alert(error, "error");
          });
      } else {
        this.itemsScheduled = [];
      }
    },
  },
  created() {
    _sDefinition
      .definitiongroup(
        this.paramsDef.defID,
        this.paramsDef.dgrID,
        this.paramsDef.requestID
      )
      .then((r) => {
        if (r.status === 200) {
          this.days = r.data;
        }
      })
      .catch(() => {
        this.$fun.alert("No se pudieron cargar los días.");
      });
  },
};
</script>

<style scoped> 
  body {
    font-family: Arial, sans-serif;
    margin: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th, td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #000; 
  }

  th {
    background-color: #f5f5f5; 
    font-weight: bold;
    border: 1px solid #000;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  tr:hover {
    background-color: #f9f9f9;
  }

</style>