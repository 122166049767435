import Service from "../Service";

const resource = "HmnSchedules/";

export default {
  save(cmp, requestID) {
    return Service.post(resource + "save", cmp, {
      params: { requestID: requestID },
    });
  },

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
  list(requestID) {
    return Service.post(
      resource + "list",
      {},
      {
        params: { requestID: requestID },
      }
    );
  },
  getDetailsByID(SdsID, requestID) {
    return Service.post(
      resource + "getDetailsByID",
      {},
      {
        params: { SdsID: SdsID, requestID: requestID },
      }
    );
  },
  saveDetailsByID(details, requestID) {
    return Service.post(resource + "saveDetailsByID", details, {
      params: { requestID: requestID },
    });
  },
  deleteDetailByID(SdsID, requestID) {
    return Service.post(
      resource + "deleteDetailByID",
      {},
      {
        params: { SddID: SdsID, requestID: requestID },
      }
    );
  },
};
